import styles from "./SignatairesInfos.module.css";
import { useQuery } from "@tanstack/react-query";


function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

export default function SignatairesInfos({
  inputValues,
  changeFn,
  pageError,
  PMId,
}) {
  async function PPtoPM(PMId) {
    const link = `${process.env.REACT_APP_API_URL}/conv/PPtoPM/${PMId}`
    // const link = `http://localhost:8080/conv/PPtoPM/${PMId}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    return data;
  }

  const { data: PPPM = [] } = useQuery({
    queryKey: ["PPtoPM"],
    queryFn: () => PPtoPM(PMId),
    refetchOnWindowFocus: false,
    enabled: PMId != undefined && Object.keys(inputValues).length == 0 && isNumber(parseInt(PMId.split(" - ")[0])),
  });

  return (
    <div className={styles.container}>
      <p className={styles.textTitle}>SIGNATAIRE(S)</p>
      {(![...new Set(Object.values(inputValues))].includes(true) &&
                pageError == true) && (
                  <p style={{ fontSize: "1rem", color:"red" }}>
                    Vous devez sélectionner au moins un signataire
                  </p>
      )}
      <div className={styles.table}>
        <table className={styles.tableSearch}>
          <thead>
            <tr>
            <th style={{ width: "45%" }}>
              <b>Libellé</b>
            </th>
            <th style={{ width: "45%" }}>
              <b>Fonction</b>
            </th>
            <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {PPPM.length != 0 &&
              PPPM.map((data) => {
                return (
                  <tr key={data["tiLib"]}>
                    <td>{data["tiLib"]}</td>
                    <td>{data["relTyp"]}</td>
                    <td>
                      <input
                        type="checkbox"
                        id="tiId"
                        name="tiId"
                        value={
                          Object.keys(inputValues).includes(data["tiId"])
                            ? false
                            : inputValues[data["tiId"]]
                        }
                        onChange={(e) =>
                          changeFn(e, "signataires", data["tiId"])
                        }
                        className={styles.input}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* {inputValues["dirigeants"].length != 0 && (
        <DirigeantsVisu dirigeants={inputValues["dirigeants"]} removeDirOverlayFn={removeDirOverlayFn} modifDirOverlayFn={modifDirOverlayFn} />
      )} */}
    </div>
  );
}
