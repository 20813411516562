import ReactDOM from "react-dom";
import styles from "./OverlayAddAvenantLocal.module.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { BounceLoader } from "react-spinners";
import poubBlanc from "../../../assets/poubBlanc.png";
import { compareArraysOfObjects } from "../../../utils/functions";

function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because January is 0
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function isValidDate(dateString) {
  // Regex to check the format
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateString.match(regex)) {
    return false; // Fails regex check
  }

  const [year, month, day] = dateString.split("-").map(Number);

  // Check for valid month and day numbers
  if (month < 1 || month > 12 || day < 1 || day > 31 || year < 2000) {
    return false;
  }

  // Create a date instance and check if the numbers match
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export default function OverlayAddAvenantLocal({
  openOverlayFn,
  ugAlready,
  inputValues,
  convAge,
}) {
  const { convId, convVrs = "latest" } = useParams();
  const [newLocal, setNewLocal] = useState({
    ugInt: "",
    ugId: "",
    ugconvDtd: "",
    ugconvDtf: "",
    ugconvSurf: "",
    ugconvSurfMax: "",
    rubconvMnt: "",
    rubRef: "Redevance",
  });

  const [currentLocaux, setCurrentLocaux] = useState(inputValues["locaux"]);
  const [isAjoutLocal, setIsAjoutLocal] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [pageCurrentLocalError, setPageCurrentLocalError] = useState(false);
  const queryClient = useQueryClient();

  const dateToday = getCurrentDate();

  async function allUGs(dateDebut) {
    if (isValidDate(dateDebut)) {
      const link = `${process.env.REACT_APP_API_URL}/conv/ugsAvenantLocal/${dateDebut}/${convAge}`;
      // const link = `http://localhost:8080/conv/ugsAvenantLocal/${dateDebut}/${convAge}`;
      const response = await fetch(link);
      if (!response.ok) {
        throw "il y a un pb";
      }
      const data = await response.json();
      return data;
    } else {
      return [];
    }
  }

  async function allUGsToday() {
    const link = `${process.env.REACT_APP_API_URL}/conv/ugsToday/${convId}`;
    // const link = `http://localhost:8080/conv/ugsToday/${convId}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    return data;
  }

  const { data: ugsInit = [] } = useQuery({
    queryKey: ["Patrimoine", newLocal["ugconvDtd"]],
    queryFn: () => allUGs(newLocal["ugconvDtd"]),
    // enabled: newLocal["ugconvDtd"] != "",
    refetchOnWindowFocus: false,
  });

  const { data: ugsInitCurrent = [] } = useQuery({
    queryKey: ["Patrimoine", "Modification", dateToday],
    queryFn: () => allUGsToday(),
    refetchOnWindowFocus: false,
  });

  const changeLocal = (e) => {
    setNewLocal((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (e.target.name == "ugId") {
        const ugId = e.target.value;
        newState[e.target.name] = ugId;
        const selectedData = ugsInit.find((data) => data.ugId == ugId);
        newState["ugInt"] = selectedData["ugInt"];
        newState["ugconvSurfMax"] =
          selectedData["ugSurf"] - selectedData["TotalSurf"];
        newState["ugconvSurf"] = newState["ugconvSurfMax"];
        newState["rubconvMnt"] =
          (selectedData["Prix"] * newState["ugconvSurf"]) /
          selectedData["ugSurf"];
      } else if (e.target.name == "ugconvSurf") {
        const newSurf = e.target.value;
        newState[e.target.name] = newSurf;
        newState["rubconvMnt"] =
          (newSurf * prev["rubconvMnt"]) / prev["ugconvSurf"];
      } else {
        newState[e.target.name] = e.target.value;
      }
      return newState;
    });
  };

  const changeCurrentLocal = (e, idx, maximumDiff = "") => {
    setCurrentLocaux((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));
      if (
        e.target.name == "ugconvSurf" &&
        e.target.value != "" &&
        e.target.value <= maximumDiff
      ) {
        const newSurf = e.target.value;
        newState[idx][e.target.name] = newSurf;
        newState[idx]["rubconvMnt"] =
          (newSurf * prev[idx]["rubconvMnt"]) / prev[idx]["ugconvSurf"];
      } else if (e.target.name == "ugconvDtf") {
        newState[idx][e.target.name] = e.target.value;
      }
      return newState;
    });
  };

  async function updateConvAvLocal(data) {
    const link = `${process.env.REACT_APP_API_URL}/conv/avenantLocal`;
    // const link = `http://localhost:8080/conv/avenantLocal`;
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const dataReceived = await response.json();
    return dataReceived;
  }

  const mutationDataUpdate = useMutation({
    mutationFn: (data) => updateConvAvLocal(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Convention", convId, convVrs]);
      queryClient.invalidateQueries(["Convention", "Historique", convId]);
      openOverlayFn(false);
    },
  });

  // const removeLocalOverlayFn = (idx) => {
  //   setCurrentLocaux((prev) => {
  //     let newState = JSON.parse(JSON.stringify(prev));
  //     newState = [
  //       ...newState.slice(0, idx),
  //       ...newState.slice(idx + 1, newState.length),
  //     ];
  //     return newState;
  //   });
  // };

  const validateCurrentLocaux = currentLocaux
    ? currentLocaux
        .map(
          (data) =>
            data["ugconvSurf"] != "" &&
            (inputValues["infos"]["convDtf"]
              ? new Date(data["ugconvDtf"]) <=
                  new Date(inputValues["infos"]["convDtf"]) &&
                new Date(data["ugconvDtf"]) >=
                  new Date(inputValues["infos"]["convDtd"])
              : true)
        )
        .includes(false)
    : false;

  const checkAndSend = () => {
    if (
      isAjoutLocal &&
      (newLocal["ugId"] == "" ||
        newLocal["ugconvDtd"] == "" ||
        newLocal["ugconvSurf"] == "" ||
        (inputValues["infos"]["convDtf"] && newLocal["ugconvDtf"] == ""))
    ) {
      setPageError(true);
    } else if (validateCurrentLocaux) {
      setPageCurrentLocalError(true);
    } else {
      setPageError(false);
      mutationDataUpdate.mutate({
        prevData: inputValues,
        typeAvenant: "Local",
        convId: convId,
        newLocal: newLocal,
        updatedLocaux: currentLocaux,
      });
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.overlay} onClick={() => openOverlayFn(false)}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <p className={styles.textOverlayTitle}>MODIFIER LOCAL</p>
        {currentLocaux.length != 0 && ugsInitCurrent.length != 0 && (
          <div className={styles.table}>
            <table className={styles.tableSearch}>
              <thead>
                <tr>
                  <th>
                    <b>Intitulé</b>
                  </th>
                  <th>
                    <b>Surface loué</b>
                  </th>
                  <th>
                    <b>Prix Mensuel</b>
                  </th>
                  <th>
                    <b>Date Fin</b>
                  </th>
                </tr>
                {/* <th style={{ width: "10%" }}></th> */}
              </thead>
              <tbody>
                {currentLocaux.map((local, idx) => {
                  const maximum = ugsInitCurrent.find(
                    (data) => data.ugId == local["ugId"]
                  ); // ugSurf == surface du local, totalSurf == surfaceTotal loué actuellement,
                  //ex: 16 - 8 + 4 = 12
                  const maximumDiff =
                    maximum["ugSurf"] -
                    maximum["TotalSurf"] +
                    inputValues["locaux"][idx]["ugconvSurf"];
                  return (
                    <tr key={idx}>
                      <td>{local["ugInt"]}</td>
                      <td>
                        <input
                          type="number"
                          id="ugconvSurf"
                          name="ugconvSurf"
                          value={local["ugconvSurf"]}
                          onChange={(e) =>
                            changeCurrentLocal(e, idx, maximumDiff)
                          }
                          className={styles.input}
                          min="1"
                          max={maximumDiff}
                        />
                        <div>
                          {local["ugconvSurf"] == "" &&
                            pageCurrentLocalError == true && (
                              <small className={styles.small}>
                                Ce champs est requis
                              </small>
                            )}
                        </div>
                      </td>
                      <td>{local["rubconvMnt"]}</td>
                      {/* {currentLocaux.length > 1 ? (
                        <td>
                          <img
                            src={poubBlanc}
                            className={styles.logo}
                            onClick={() => removeLocalOverlayFn(idx)}
                          />
                        </td>
                      ) : (
                        <td></td>
                      )} */}
                      <td>
                        <input
                          type="date"
                          id="ugconvDtf"
                          name="ugconvDtf"
                          value={local["ugconvDtf"]}
                          onChange={(e) => changeCurrentLocal(e, idx)}
                          className={styles.input}
                          min={inputValues["infos"]["convDtd"]}
                          max={
                            inputValues["infos"]["convDtf"]
                              ? inputValues["infos"]["convDtf"]
                              : ""
                          }
                        />
                        <div>
                          {(inputValues["infos"]["convDtf"]
                            ? new Date(local["ugconvDtf"]) >=
                                new Date(inputValues["infos"]["convDtf"]) ||
                              new Date(local["ugconvDtf"]) <=
                                new Date(inputValues["infos"]["convDtd"])
                            : false) &&
                            pageCurrentLocalError == true && (
                              <small className={styles.small}>
                                Veuillez entrer une date correcte
                              </small>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div>
          <label htmlFor="ajoutLocal" style={{ marginRight: "1rem" }}>
            AJOUTER LOCAL
          </label>
          <input
            type="checkbox"
            id="ajoutLocal"
            name="ajoutLocal"
            checked={isAjoutLocal}
            onChange={(e) => setIsAjoutLocal(!isAjoutLocal)}
            className={styles.input}
          />
        </div>
        {/* <p className={styles.textOverlayTitle}>AJOUTER LOCAL</p> */}
        {isAjoutLocal && (
          <div>
            <div className={styles.fieldContainer}>
              <label className={styles.label} htmlFor="ugconvDtd">
                Date de début*
              </label>
              <input
                type="date"
                id="ugconvDtd"
                name="ugconvDtd"
                value={newLocal["ugconvDtd"]}
                onChange={(e) => changeLocal(e)}
                className={styles.input}
              />
              {newLocal["ugconvDtd"] == "" && pageError == true && (
                <small className={styles.small}>Ce champs est requis</small>
              )}
            </div>
            {ugsInit.length != 0 && (
              <>
                <div className={styles.rowContainer}>
                  <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="ugId">
                      Intitulé*
                    </label>
                    <select
                      value={newLocal["ugId"]}
                      onChange={(e) => changeLocal(e)}
                      name="ugId"
                      id="ugId"
                      className={styles.inputSelect}
                    >
                      <option value="">----------------------</option>
                      {ugsInit.map((data) => {
                        if (!ugAlready.includes(data.ugId.toString())) {
                          return (
                            <option value={data.ugId} key={data.ugId}>
                              {data.ugRef} - {data.ugInt}
                            </option>
                          );
                        }
                      })}
                    </select>
                    {newLocal["ugId"] == "" && pageError == true && (
                      <small className={styles.small}>
                        Ce champs est requis
                      </small>
                    )}
                  </div>
                  <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="ugconvDtf">
                      Date de fin
                    </label>
                    <input
                      type="date"
                      id="ugconvDtf"
                      name="ugconvDtf"
                      value={newLocal["ugconvDtf"]}
                      onChange={(e) => changeLocal(e)}
                      className={styles.input}
                      min={newLocal["ugconvDtd"] ? newLocal["ugconvDtd"] : inputValues["infos"]["convDtd"]}
                      max={
                        inputValues["infos"]["convDtf"]
                          ? inputValues["infos"]["convDtf"]
                          : ""
                      }
                    />
                    {inputValues["infos"]["convDtf"] &&
                      newLocal["ugconvDtf"] == "" &&
                      pageError == true && (
                        <small className={styles.small}>
                          Ce champs est requis car convention avec date de fin
                        </small>
                      )}
                  </div>
                </div>
                <div className={styles.rowContainer}>
                  <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="ugconvSurf">
                      Surface louée*
                    </label>
                    <input
                      type="number"
                      id="ugconvSurf"
                      name="ugconvSurf"
                      value={newLocal["ugconvSurf"]}
                      onChange={(e) => changeLocal(e)}
                      className={styles.input}
                      min="0"
                      max={newLocal["ugconvSurfMax"]}
                    />
                    {newLocal["ugconvSurf"] == "" && pageError == true && (
                      <small className={styles.small}>
                        Ce champs est requis
                      </small>
                    )}
                  </div>
                  <div className={styles.fieldContainer}>
                    <label className={styles.label} htmlFor="rubconvMnt">
                      Prix Mensuel*
                    </label>
                    <input
                      type="number"
                      id="rubconvMnt"
                      name="rubconvMnt"
                      value={newLocal["rubconvMnt"]}
                      onChange={(e) => changeLocal(e)}
                      className={styles.input}
                      readOnly={true}
                      min="0"
                    />
                    {newLocal["rubconvMnt"] == "" && pageError == true && (
                      <small className={styles.small}>
                        Ce champs est requis
                      </small>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className={styles.buttonValid}
            disabled={
              mutationDataUpdate.isPending ||
              (isAjoutLocal == false &&
                compareArraysOfObjects(currentLocaux, inputValues["locaux"]))
            }
            onClick={() => checkAndSend()}
          >
            Modifier
          </button>
          <button
            className={styles.buttonValid}
            onClick={() => openOverlayFn(false)}
          >
            Retour
          </button>
        </div>
        {mutationDataUpdate.isPending == true && (
          <div className={styles.bounce}>
            <BounceLoader color="black" />
          </div>
        )}
      </div>
    </div>,
    document.getElementById("overlay")
  );
}
