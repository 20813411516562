import './index.css';
import styles from "./App.module.css"
import { RouterProvider, createBrowserRouter,Navigate, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from './pages/Login/Login';
import Menu from './pages/Menu/Menu';
import Header from './components/Header';
import RechercheUG from './pages/RechercheUG/RechercheUG';
import VisualisationUG from './pages/VisualisationUG/VisualisationUG';
import CreationTiers from './pages/CreationTiers/creerTiers';
import CreationUG from './pages/CreationUGExcel/CreationUG';
import RechercheTiers from './pages/RechercheTiers/rechercheTiers';
import VisualisationTiers from './pages/visualisationTiers/visualisationTiers';
import { createContext, useEffect, useState } from 'react';
import VisualisationConv from './pages/visualisationConv/VisualisationConv';
import RechercheConv from './pages/RechercheConvention/rechercheConv';
import CreationTiersExcel from './pages/CreationTiersExcel/creationTiersExcel';
import Admin from './pages/Administration/administration';
import Notifications from './pages/Notifications/notifications';
import Statistiques from './pages/Statistiques/statistiques';
import CreationUGSeul from './pages/CreationUG/CreationUGSeul';
import CreerConv from './pages/CreationConv/creerConv';
import { BounceLoader } from "react-spinners";

export const queryClient = new QueryClient();


export const HeaderContext = createContext();


export function HeaderProvider({ children }) {
  const navigate = useNavigate()
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  // Tiers
  const [searchData, setSearchData] = useState(["empty"]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterFieldTiers, setFilterFieldsTiers] = useState({dateDebut:"", dateFin: "",porteurProjet:true, PM:true})

  // Patrimoine
  const [searchDataPatrimoine, setSearchDataPatrimoine] = useState(["empty"]);
  const [isLoadingPatrimoine, setIsLoadingPatrimoine] = useState(false);
  const [filterFieldPatrimoine, setFilterFieldsPatrimoine] = useState({dateDispo:"", loué:true, disponible:true})
  // const [filterFieldPatrimoine, setFilterFieldsPatrimoine] = useState({dateDebut:"", dateFin: "", loué:true, disponible:true})
  // useState({dateDeb:"", dateFin:"", loué:true, disponible:true})

  // Patrimoine
  const [searchDataConv, setSearchDataConv] = useState(["empty"]);
  const [isLoadingConv, setIsLoadingConv] = useState(false);
  const [filterFieldConv, setFilterFieldsConv] = useState({dateDebut:"", dateFin: "", active:true, resilié:true})
  
  //Suivi
  const [filterFieldSuivi, setFilterFieldsSuivi] = useState({dateDebut:"", dateFin:"", sujet:""})

  //Recherches States
  const [numberElementsMaxConv, setNumberElementsMaxConv] = useState(10);
  const [prevNumConv, setPrevNumConv] = useState(0);
  const [nextNumConv, setNextNumConv] = useState(10);

  const [numberElementsMaxTiers, setNumberElementsMaxTiers] = useState(10);
  const [prevNumTiers, setPrevNumTiers] = useState(0);
  const [nextNumTiers, setNextNumTiers] = useState(10);

  const [numberElementsMaxLocaux, setNumberElementsMaxLocaux] = useState(10);
  const [prevNumLocaux, setPrevNumLocaux] = useState(0);
  const [nextNumLocaux, setNextNumLocaux] = useState(10);


  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        setIsLoadingAuth(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/protected`, {
          // const response = await fetch('http://localhost:8080/users/protected', {
          credentials: 'include' // Important for cookies to be sent and received
        });
        // const data = await response.json()
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsLoadingAuth(false)
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoadingAuth(false)
      }
    };
    checkAuthentication();
  }, []);

  return (
    <HeaderContext.Provider value={{ searchData, setSearchData, isLoading, setIsLoading, filterFieldTiers, setFilterFieldsTiers,
      searchDataPatrimoine, setSearchDataPatrimoine,  isLoadingPatrimoine, setIsLoadingPatrimoine, filterFieldPatrimoine, setFilterFieldsPatrimoine,
      searchDataConv, setSearchDataConv, isLoadingConv, setIsLoadingConv, filterFieldConv, setFilterFieldsConv, isAuthenticated, filterFieldSuivi, setFilterFieldsSuivi,
      numberElementsMaxConv, setNumberElementsMaxConv, prevNumConv, setPrevNumConv, nextNumConv, setNextNumConv,
      numberElementsMaxTiers, setNumberElementsMaxTiers, prevNumTiers, setPrevNumTiers, nextNumTiers, setNextNumTiers,
      numberElementsMaxLocaux, setNumberElementsMaxLocaux, prevNumLocaux, setPrevNumLocaux, nextNumLocaux, setNextNumLocaux
      }}>
      {isLoadingAuth ?
      <div className={styles.bounce}>
        <BounceLoader color="black" />
      </div>
      : isAuthenticated ? children : 
      <p style={{fontSize:"1rem", margin:"2rem"}}>Vous n'êtes pas autorisé à parcourir cette page, veuillez vous connecter tout d'abord.</p>}
    </HeaderContext.Provider>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/pageConnecte',
    element:       
      <HeaderProvider>
          <Header />
      </HeaderProvider>,
    children:[
      {
        path: 'menu',
        element: <Menu />,
      },
      {
        path: 'Notifications',
        element: <Notifications />,
      },
      {
        path: 'administration',
        element: <Admin />,
      },
      {
        path: 'recherchePatrimoine',
        element: <RechercheUG />,
      },
      {
        path: 'recherchePatrimoine/patrimoine/:ugNum',
        element: <VisualisationUG />,
      },
      {
        path: 'creerPatrimoine',
        element: <CreationUGSeul />,
      },
      {
        path: 'creerPatrimoineExcel',
        element: <CreationUG />,
      },
      {
        path: 'rechercheTiers/:type/:rubrique',
        element: <RechercheTiers />,
      },
      {
        path: 'rechercheTiers/tiers/:id',
        element: <VisualisationTiers />,
      },
      {
        path: 'creerTiers/:type',
        element: <CreationTiers />,
      },
      {
        path: 'creerTiers/excel',
        element: <CreationTiersExcel />,
      },
      {
        path: 'rechercheConvention',
        element: <RechercheConv/>,
      },
      {
        path: 'rechercheConvention/convention/:convId/:convVrs',
        element: <VisualisationConv />,
      },
      {
        path: 'rechercheConvention/convention/:convId/latest',
        element: <VisualisationConv />,
      },
      {
        path: 'creerConvention/:type',
        element: <CreerConv />,
      },
      {
        path: 'statistiques',
        element: <Statistiques />,
      },
    ]
  },
])

function App() {
  return ( 
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>)
}

export default App;
