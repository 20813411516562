import { useState } from "react";
import styles from "./authentication.module.css"
import { useNavigate } from "react-router-dom"

function Authentication () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isClicked, setIsClicked] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsClicked(true)
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
            // const response = await fetch('http://localhost:8080/users/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password }),
            credentials: 'include'
        })
        const data = await response.json()
        if (!response.ok) {
          setIsClicked(false)
          throw new Error(data);
        } else {
          setIsClicked(false)
          return navigate('./PageConnecte/menu');          
        }
      } catch (error) {
        setIsClicked(false)
        alert(error.message)
        }
      }

    return (
        <form className={styles.formContainer} onSubmit={(e) => handleSubmit(e)}>
            <div className={styles.fieldContainer}>
                <label className={styles.label} htmlFor="Email">Email</label>
                <input type="email" id="Email" data-testid="Email" placeholder="xxx@espace-carco.com" onChange={(e) => setEmail(e.target.value)} className={styles.input}/>
            </div>
            <div className={styles.fieldContainer}>
                <label className={styles.label} htmlFor="Password">Mot de passe</label>
                <input type="password" id="Password" placeholder="" onChange={(e) => setPassword(e.target.value)} className={styles.input}/>
            </div>
            {/* onClick={() => menu()} */}
            <button type="submit" className={styles.button} disabled={isClicked}>Se connecter</button>
            {/* <Link className={styles.mdpForgotten}>Mot de passe oublié ?</Link> */}
        </form>
    )
}

export default Authentication