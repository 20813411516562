import styles from "./creationTiersExcel.module.css"
import ImportButton from "../../components/ImportButton/ImportButton"
import DownloadButton from "../../components/downloadButton/DownloadButton"
import { useQuery } from "@tanstack/react-query";

export default function CreationTiersExcel () {

    const downloadTemplate = async () => {
        const link = `${process.env.REACT_APP_API_URL}/tiers/downloadTemplate`
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        window.open(data["downloadLink"], '_blank')   
    }

    async function lastValueTiersID() {
        const link = `${process.env.REACT_APP_API_URL}/tiers/lastValueTiId`
        const response = await fetch(link)
        if (!response.ok) {
            throw "il y a un pb"
        }
        const data = await response.json()
        return data
    }

    const {data: lastValueID = {}} = useQuery({
        queryKey:["tiId", "last"],
        queryFn:() => lastValueTiersID(),
        refetchOnWindowFocus: false
    })


    return (
        <div className={styles.container}>
            <div className={styles.textButContainer}>
                <p className={styles.text}>Veuillez, s'il vous plait, suivre la trame du template pour ajouter de nouveaux Tiers.  <br /> <br />
                Vous devez <b>obligatoirement</b> débuter avec IDENTIFIANT TIERS <b>{lastValueID["tiId"] == undefined ? 1 : lastValueID["tiId"] + 1}</b>.  <br /> <br />
                </p>
                <div className={styles.buttonsContainer}>
                    <ImportButton text="Importer" from="Tiers" />
                    <DownloadButton text="Télécharger le template" onClick={downloadTemplate} />
                </div>
            </div>
        </div>
    )
}