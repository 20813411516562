import styles from "./VisualisationConv.module.css";
import { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import shareLogo from "../../assets/shareLogo.png";
import InfosVisu from "../../components/visuConv/InfosVisu/InfosVisu";
import SignatairesVisu from "../../components/visuConv/SignatairesVisu/SignatairesVisu";
import LocauxVisu from "../../components/visuConv/LocauxVisu/LocauxVisu";
import EquipementsVisu from "../../components/visuConv/EquipementsVisu/EquipementsVisu";
import HistoriqueVisu from "../../components/visuConv/HistoriqueVisu/HistoriqueVisu";
import OverlayResiliation from "../../components/visuConv/overlayResiliation/overlayResiliation";
import FichiersVisu from "../../components/VisuUG/fichiersVisu/fichiersVisu";
import importerBlanc from "../../assets/importerBlanc.png";
import wordLogo from "../../assets/word.png";
import excelLogo from "../../assets/excel.png";
import pdfLogo from "../../assets/pdf.png";
import docLambda from "../../assets/documentLambda.png";
import OverlayRemoveFileConv from "../../components/visuConv/overlayRemoveFile/overlayRemoveFileConv";
import OverlayAddEqVisu from "../../components/visuConv/OverlayAddEqVisu/OverlayAddEqVisu";
import OverlayAddAvenant from "../../components/visuConv/OverlayAddAvenant/OverlayAddAvenant";
import OverlayAddAvenantLocal from "../../components/visuConv/OverlayAddAvenantLocal/OverlayAddAvenantLocal";
import OverlayAddEntiteAvenant from "../../components/visuConv/OverlayAddEntiteAvenant/OverlayAddEntiteAvenant";
import OverlayAddStatutJurAvenant from "../../components/visuConv/OverlayAddStatutJurAvenant/OverlayAddStatutJurAvenant";
import OverlayAddAvenantCow from "../../components/visuConv/OverlayAddAvenantCow/OverlayAddAvenantCow";

export default function VisualisationConv() {
  const { convId, convVrs = "latest" } = useParams();
  const location = useLocation();
  const path = process.env.REACT_APP_API_URL == "production" ? "https://carcoapp.mbe-consult.fr" + location.pathname : "http://localhost:3000" + location.pathname
  const [editable, setEditable] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [resilitationOverlay, setResilitationOverlay] = useState(false);
  const [newEq, setNewEq] = useState(false);
  const [newAvenant, setNewAvenant] = useState(false);
  const [typeAvenant, setTypeAvenant] = useState(false);
  const [newAvenantLocal, setNewAvenantLocal] = useState(false);
  const [newAvenantEntite, setNewAvenantEntite] = useState(false);
  const [newAvenantCoworking, setNewAvenantCoworking] = useState(false);
  const [newAvenantStatutJur, setNewAvenantStatutJur] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [fileToRemove, setFileToRemove] = useState([]);
  const [IsLoadingPostFile, setIsLoadingPostFile] = useState(false);
  const inputFile = useRef(null);
  const queryClient = useQueryClient();

  async function getConvInfos(id, convVrs = "latest") {
    const link = `${process.env.REACT_APP_API_URL}/conv/infos/${id}/${convVrs}`
    // const link = `http://localhost:8080/conv/infos/${id}/${convVrs}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    setInputValues(data)
    return data;
  }

  const { data: infos = [], isLoading } = useQuery({
    queryKey: ["Convention", convId, convVrs],
    queryFn: () => getConvInfos(convId, convVrs),
    refetchOnWindowFocus: false,
  });

  async function getFiles(id) {
    const link = `${process.env.REACT_APP_API_URL}/conv/files/${id}`
    // const link = `http://localhost:8080/conv/files/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    const listFiles = [];
    const formatsWord = [
      ".doc",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".rtf",
      ".txt",
      ".xml",
    ];
    const excelFormats = [
      ".xls",
      ".xlsx",
      ".xlsm",
      ".xlsb",
      ".xlt",
      ".xltx",
      ".xltm",
      ".csv",
      ".xml",
    ];
    const pdfFormat = [".pdf"];
    for (let obj of data) {
      const link = obj["url"];
      const filename = obj["filename"];
      if (formatsWord.some((format) => filename.includes(format))) {
        listFiles.push([wordLogo, link, filename]);
      } else if (excelFormats.some((format) => filename.includes(format))) {
        listFiles.push([excelLogo, link, filename]);
      } else if (pdfFormat.some((format) => filename.includes(format))) {
        listFiles.push([pdfLogo, link, filename]);
      } else {
        listFiles.push([docLambda, link, filename]);
      }
    }
    return listFiles;
  }

  const { data: files = [], isLoading: isLoadingFiles } = useQuery({
    queryKey: ["Convention", "Files", convId],
    queryFn: () => [], // getFiles(convId),
    refetchOnWindowFocus: false,
  });


  const handleFileUpload = async (event) => {
    setIsLoadingPostFile(true);
    const files = event.target.files;

    try {
      const presignedUrlPromises = Array.from(files).map(file => {
        return fetch(`${process.env.REACT_APP_API_URL}/conv/generatePresignedUrl/${convId}/${encodeURIComponent(file.name)}`)
          .then(response => response.json());
      });
  
      const presignedUrls = await Promise.all(presignedUrlPromises);

      const uploadPromises = presignedUrls.map((presignedUrl, index) => {
        return fetch(presignedUrl.url, {
          method: 'PUT',
          headers: {
            'Content-Type': files[index].type,
          },
          body: files[index],
        });
      });

  
      const uploadResponses = await Promise.all(uploadPromises);
  
      const allUploadsSuccessful = uploadResponses.every(response => response.ok);
  
      if (allUploadsSuccessful) {
        queryClient.invalidateQueries(["Convention", "Files", convId]);
      } else {
        console.error('File upload failed for some files');
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    } finally {
      setIsLoadingPostFile(false);
    }
  };

  // const handleFileUpload = async (event) => {
  //   setIsLoadingPostFile(true);
  //   const files = event.target.files;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("files", files[i]);
  //   }
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/conv/uploadFiles/${convId}`, {
  //         method: 'POST',
  //         body: formData,
  //     });
  //     // const response = await fetch(
  //     //   `http://localhost:8080/conv/uploadFiles/${convId}`,
  //     //   {
  //     //     method: "POST",
  //     //     body: formData,
  //     //   }
  //     // );
  //     if (response.ok) {
  //       queryClient.invalidateQueries(["Convention", "Files", convId]);
  //     } else {
  //       console.error("File upload failed:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during file upload:", error);
  //   } finally {
  //     setIsLoadingPostFile(false); // End loading
  //   }
  // };

  // useEffect(() => {
  //   if (infos.length != 0) {
  //     setInputValues(infos);
  //   }
  // }, [infos]);

  async function getConvHistorique(id) {
    const link = `${process.env.REACT_APP_API_URL}/conv/historique/${id}`
    // const link = `http://localhost:8080/conv/historique/${id}`;
    const response = await fetch(link);
    if (!response.ok) {
      throw "Il y a un problème avec le serveur";
    }
    const data = await response.json();
    return data;
  }

  const { data: historique = [], isLoading: isLoadingHistorique } = useQuery({
    queryKey: ["Convention", "Historique", convId],
    queryFn: () => getConvHistorique(convId),
    refetchOnWindowFocus: false,
    enabled: infos.length != 0
  });

  const downloadConvention = async (type, formule) => {
    let link;
    if (formule == "PEPINIERE") {
      if (type == "Avenant 2éme année" || type == "Avenant 3éme année") {
        const age = type.split(" ")[1][0] - 1
        link = `${process.env.REACT_APP_API_URL}/docs/AvenantPrix/${convId}/${convVrs}/${age}`
        // link = `http://localhost:8080/docs/AvenantPrix/${convId}/${convVrs}/${age}`;
      } else if (type.includes("Avenant centre d'affaires")) {
        const age = parseInt(type.split(" ").pop()) + 2
        link = `${process.env.REACT_APP_API_URL}/docs/AvenantPrix/${convId}/${convVrs}/${age}`
      } else if (type == "Avenant Local") {
        link = `${process.env.REACT_APP_API_URL}/docs/AvenantSurface/${convId}/${convVrs}`
        // link = `http://localhost:8080/docs/AvenantSurface/${convId}/${convVrs}`;
      } else if (type == "Avenant Entité") {
        link = `${process.env.REACT_APP_API_URL}/docs/AvenantEntite/${convId}/${convVrs}`
        // link = `http://localhost:8080/docs/AvenantEntite/${convId}/${convVrs}`;
      } else {
        link = `${process.env.REACT_APP_API_URL}/docs/conventionWord/${convId}/${convVrs}`
        // link = `http://localhost:8080/docs/conventionWord/${convId}/${convVrs}`;
      }
    } else {
      if (type == 'Avenant Coworking') {
        const age = type.split(" ")[1][0] - 1
        link = `${process.env.REACT_APP_API_URL}/docs/AvenantCoworking/${convId}/${convVrs}/${age}`
        // link = `http://localhost:8080/docs/AvenantCoworking/${convId}/${convVrs}/${age}`;
      } else {
        link = `${process.env.REACT_APP_API_URL}/docs/conventionCoWorkingWord/${convId}/${convVrs}`
        // link = `http://localhost:8080/docs/conventionCoWorkingWord/${convId}/${convVrs}`;
      }
    }
    window.open(link, "_blank");
  };

  const downloadCharte = async () => {
    const link = `${process.env.REACT_APP_API_URL}/docs/charteAccompagnementPepiniere/${convId}/${convVrs}`
    // const link = `http://localhost:8080/docs/charteAccompagnementPepiniere/${convId}/${convVrs}`;
    window.open(link, "_blank");
  };

  const downloadReglement = async () => {
    const link = `${process.env.REACT_APP_API_URL}/docs/downloadReglement`
    // const link = "http://localhost:8080/docs/downloadReglement";
    const response = await fetch(link);
    if (!response.ok) {
      throw "il y a un pb";
    }
    const data = await response.json();
    window.open(data["downloadLink"], "_blank");
  };

  const now = new Date();
  const dateFinConv = inputValues?.infos?.convDtf
    ? new Date(inputValues["infos"]["convDtf"])
    : undefined;
  const dateDebConv = inputValues?.infos?.convDtd
    ? new Date(inputValues["infos"]["convDtd"])
    : undefined;
  const isNotExpired =
    inputValues["infos"] && dateFinConv != undefined
      ? now <= dateFinConv
      : inputValues.length != 0 &&
        dateFinConv == undefined &&
        dateDebConv != undefined
      ? now >= dateDebConv
      : true;

  function copyTextToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard", err);
      });
  }

  const removeFileOverlayFn = (data = []) => {
    setFileToRemove(data);
    setRemoveFile(!removeFile);
  };

  const newAvenantFn = (type) => {
    setTypeAvenant(type);
    setNewAvenant((prev) => !prev);
  };

  const newAvenantCowFn = (type) => {
    setTypeAvenant(type);
    setNewAvenantCoworking((prev) => !prev);
  };

  const historiqueEvents = historique ? historique.map((data) => data.convAv) : []
  function range(start, stop) {
    return Array.from({ length: stop - start }, (v, k) => k + start);
  }

  
  return (
    <div className={styles.container}>
      {removeFile && (
        <OverlayRemoveFileConv
          removeOverlayFn={removeFileOverlayFn}
          fileToRemove={fileToRemove}
        />
      )}

      {newAvenant && (
        <OverlayAddAvenant
          openOverlayFn={setNewAvenant}
          inputValues={inputValues}
          setInputValues={setInputValues}
          convAge={inputValues["infos"]["convAge"]}
          typeAvenant={typeAvenant}
        />
      )}

      {newAvenantLocal && (
        <OverlayAddAvenantLocal
          openOverlayFn={setNewAvenantLocal}
          inputValues={inputValues}
          setInputValues={setInputValues}
          ugAlready={inputValues["locaux"].map((data) => data.ugId.toString())}
          convAge={inputValues["infos"]["convAge"]}
        />
      )}

      {newAvenantEntite && (
        <OverlayAddEntiteAvenant
          openOverlayFn={setNewAvenantEntite}
          inputValues={inputValues}
        />
      )}

      {newAvenantStatutJur && (
        <OverlayAddStatutJurAvenant
          openOverlayFn={setNewAvenantStatutJur}
          inputValues={inputValues}
        />
      )}

      {resilitationOverlay && (
        <OverlayResiliation
          openOverlayFn={setResilitationOverlay}
          inputValues={inputValues}
        />
      )}

      {newEq && (
        <OverlayAddEqVisu
          openOverlayFn={setNewEq}
          inputValues={inputValues}
          setInputValues={setInputValues}
          eqAlready={inputValues["equipements"].map((data) =>
            data.eqRef.toString()
          )}
          ugAlready={inputValues["locaux"].map((data) => data.ugId.toString())}
        />
      )}

      {isLoading == true && (
        <div className={styles.bounce}>
          <BounceLoader color="black" />
        </div>
      )}
      {inputValues?.infos?.convForm == "Formule Pépinière" && historique.length != 0 && (
        <>
          <div className={styles.box}>
            <p className={styles.textHeader}>
              {"Convention Pépinière " +
                (inputValues["infos"]["tipmRso"]
                  ? inputValues["infos"]["tipmRso"]
                  : "")}
            </p>
            <div className={styles.logoContainer}>
              <img
                src={shareLogo}
                className={styles.logo}
                onClick={() => copyTextToClipboard(path)}
              />
            </div>
          </div>

          <p className={styles.textTitle}>
            {"CONVENTION - V" +
              inputValues["infos"]["convVrs"] +
              "/" +
              historique[0]["convVrs"] +
              " - " +
              (inputValues["infos"]["convAv"].includes("Avenant 2éme année") ||
              inputValues["infos"]["convAv"].includes("Avenant 3éme année") ||
              inputValues["infos"]["convAv"].includes("Avenant centre d'affaires")
                ? inputValues["infos"]["convAv"].replace("éme", "ème").toUpperCase()
                : inputValues["infos"]["convAv"] == "Avenant Local"
                ? "AVENANT LOCAL"
                : inputValues["infos"]["convAv"] == "Ajout Equipement"
                ? "MODIFICATION EQUIPEMENT"
                : inputValues["infos"]["convAv"] == "Avenant Entité"
                ? "AVENANT ENTITE"
                : inputValues["infos"]["convAv"] == "Avenant Statut Juridique"
                ? "AVENANT STATUT JURIDIQUE"
                : "CONVENTION") +
              " - " +
              (isNotExpired ? "ACTIVE" : "RESILIEE")}
          </p>
          <InfosVisu inputValues={inputValues["infos"]} />
          <p className={styles.textTitle}>SIGNATAIRE(S)</p>
          {inputValues["signataires"] && (
            <SignatairesVisu signataires={inputValues["signataires"]} />
          )}

          <p className={styles.textTitle}>LOCAUX</p>
          {inputValues["locaux"] && (
            <LocauxVisu locaux={inputValues["locaux"]} />
          )}
          <p className={styles.textTitle}>ÉQUIPEMENT(S)</p>
          {isNotExpired && convVrs == "latest" && (
            <div className={styles.buttonAddContainer}>
              <button
                onClick={() => setNewEq((prev) => !prev)}
                className={styles.buttonAdd}
              >
                Ajouter un équipement
              </button>
            </div>
          )}
          {inputValues["equipements"] && (
            <EquipementsVisu equipements={inputValues["equipements"]} />
          )}

          {convVrs == "latest" && (
            <>
              <p className={styles.textTitle}>FICHIERS</p>
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputFile}
                onChange={handleFileUpload}
                multiple
              />
              {isNotExpired && (
                <div
                  className={styles.buttonAddContainer}
                  style={{
                    marginBottom: `${files.length == 0 ? "10rem" : ""}`,
                  }}
                >
                  <button
                    onClick={() => inputFile.current.click()}
                    className={styles.buttonImport}
                  >
                    <p>Importer</p>
                    <img
                      src={importerBlanc}
                      className={styles.logo}
                      style={{ height: "2rem" }}
                    />
                  </button>
                </div>
              )}
              {files.length != 0 && (
                <FichiersVisu
                  fichiers={files}
                  removeOverlayFn={removeFileOverlayFn}
                />
              )}
            </>
          )}

          {isLoadingFiles == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          {IsLoadingPostFile == true && (
            <div className={styles.bounce} style={{ marginBottom: "3rem" }}>
              <BounceLoader color="black" />
            </div>
          )}
          <p className={styles.textTitle}>HISTORIQUE</p>
          {isLoadingHistorique == true && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          )}
          {historique && <HistoriqueVisu historique={historique} />}
          {convVrs == "latest" && isNotExpired && (
            <>
              <div className={styles.buttonModifContainer}>
                {/* inputValues["infos"]["convAge"] == 1 &&  */}
                {/* inputValues["infos"]["convAge"] == 2 &&  */}
                {/* inputValues["infos"]["convAge"] > 2 */}
                {(inputValues["infos"]["convAge"] > 0 && !historiqueEvents.includes("Avenant 2éme année")) &&  (
                  <button
                    onClick={() => newAvenantFn("2éme année")}
                    className={styles.buttonModif}
                  >
                    Ajouter un avenant 2ème année
                  </button>
                )}
                {(inputValues["infos"]["convAge"] > 1 && !historiqueEvents.includes("Avenant 3éme année")) && (
                  <button
                    onClick={() => newAvenantFn("3éme année")}
                    className={styles.buttonModif}
                  >
                    Ajouter un avenant 3ème année
                  </button>
                )}
                {(inputValues["infos"]["convAge"] > 2 && 
                 range(3, parseInt(inputValues["infos"]["convAge"]) + 1).map((data) => {
                  if (!historiqueEvents.includes(`Avenant centre d'affaires ${data - 2}`)) {
                    return <button
                    onClick={() => newAvenantFn(`centre d'affaires ${data - 2}`)}
                    className={styles.buttonModif}
                  >
                    Ajouter un avenant Formule Centre d'Affaires {data - 2}
                  </button>
                  } 
                  return null;
                 })
                )}
              </div>
              {isNotExpired && <div className={styles.buttonModifContainer}>
                <button
                  onClick={() => setNewAvenantLocal((prev) => !prev)}
                  className={styles.buttonModif}
                >
                  Ajouter un avenant de changement de local
                </button>
                <button
                  onClick={() => setNewAvenantStatutJur((prev) => !prev)}
                  className={styles.buttonModif}
                >
                  Ajouter un avenant de changement de statut juridique
                </button>
                <button
                  onClick={() => setNewAvenantEntite((prev) => !prev)}
                  className={styles.buttonModif}
                >
                  Ajouter un avenant de changement d'entité
                </button>
              </div>}
            </>
          )}
          <div className={styles.buttonModifContainer}>
            {inputValues["infos"]["convAv"] != "Ajout Equipement" && <button
              onClick={() =>
                downloadConvention(inputValues["infos"]["convAv"], "PEPINIERE")
              }
              className={styles.buttonAddEnd}
            >
              Télécharger la version de la Convention
            </button>}
            <button
              onClick={() => downloadCharte()}
              className={styles.buttonAddEnd}
            >
              Télécharger la Charte d'accompagnement de la Pépinière
            </button>
            <button
              onClick={() => downloadReglement()}
              className={styles.buttonAddEnd}
            >
              Télécharger le règlement de la Pépinière
            </button>
          </div>
          {convVrs == "latest" && (
            <button
              onClick={() => setResilitationOverlay((prev) => !prev)}
              className={styles.buttonAddEnd}
              style={{ alignSelf: "center", height: "3rem", width: "auto" }}
            >
              Résilier la convention
            </button>
          )}
        </>
      )}

      {inputValues?.infos?.convForm == "Formule Coworking" && historique.length != 0 && (
        <>
              {resilitationOverlay && (
                <OverlayResiliation
                  openOverlayFn={setResilitationOverlay}
                  inputValues={inputValues}
                />
              )}


                {newAvenantCoworking && (
                  <OverlayAddAvenantCow
                    openOverlayFn={setNewAvenantCoworking}
                    inputValues={inputValues}
                    typeAvenant={typeAvenant}
                  />
                )}

          <div className={styles.box}>
            <p className={styles.textHeader}>
              {"Convention Coworking " +
                (inputValues["infos"]["tiLib"]
                  ? inputValues["infos"]["tiLib"]
                  : "")}
            </p>
            <div className={styles.logoContainer}>
              <img
                src={shareLogo}
                className={styles.logo}
                onClick={() => copyTextToClipboard(path)}
              />
              {/* <a href={`mailto:${inputValues["infos"]["tippEmail"]}`}><img src={mailLogo} className={styles.logo} /></a> */}
            </div>
          </div>

          <p className={styles.textTitle}>
            {"CONVENTION - V" +
              inputValues["infos"]["convVrs"] +
              "/" +
              historique[0]["convVrs"] +
              " - " +
              (inputValues["infos"]["convAv"] == "Prix"
                ? "AVENANT PRIX"
                : inputValues["infos"]["convAv"] == "Surface"
                ? "AVENANT SURFACE"
                : "CONVENTION") +
              " - " +
              (isNotExpired ? "ACTIVE" : "RESILIEE")}
          </p>
          <InfosVisu inputValues={inputValues["infos"]} />

          <p className={styles.textTitle}>SIGNATAIRE(S)</p>
          {inputValues["signataires"] && (
            <SignatairesVisu signataires={inputValues["signataires"]} />
          )}
          <p className={styles.textTitle}>HISTORIQUE</p>
          {isLoadingHistorique == true && (
            <div className={styles.bounce}>
              <BounceLoader color="black" />
            </div>
          )}
          {historique && <HistoriqueVisu historique={historique} />}
          {/* {(inputValues["infos"]["convAge"] > 0 && isNotExpired && !historiqueEvents.includes(`Avenant Coworking ${inputValues["infos"]["convAge"]}`)) && <div className={styles.buttonModifContainer}>
            <button
              onClick={() => setNewAvenantCoworking((prev) => !prev)}
              className={styles.buttonModif}
            >
              Ajouter un avenant {inputValues["infos"]["convAge"]}
            </button>
          </div>} */}
          <div className={styles.buttonModifContainer}>
          {(inputValues["infos"]["convAge"] > 0 && 
                 range(1, parseInt(inputValues["infos"]["convAge"]) + 1).map((data) => {
                  if (!historiqueEvents.includes(`Avenant Coworking ${data}`)) {
                    return<button
                    onClick={() => newAvenantCowFn(`Coworking ${data}`)}
                    className={styles.buttonModif}
                  >
                    Ajouter un avenant {data}
                  </button>
                  } 
                  return null;
                 })
                )}
          </div>
          
          <div className={styles.buttonModifContainer}>
            <button
              onClick={() =>
                downloadConvention(inputValues["infos"]["convAv"], "COWORKING")
              }
              className={styles.buttonAddEnd}
            >
              Télécharger la version de la Convention
            </button>
            <button
              onClick={() => downloadReglement()}
              className={styles.buttonAddEnd}
            >
              Télécharger le règlement de la Pépinière
            </button>
          </div>
          <button
            onClick={() => setResilitationOverlay((prev) => !prev)}
            className={styles.buttonAddEnd}
            style={{ alignSelf: "center", height: "3rem", width: "auto" }}
          >
            Résilier la convention
          </button>
        </>
      )}
    </div>
  );
}
