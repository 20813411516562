import { useState } from "react";
import styles from "./prixCentre.module.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BounceLoader } from "react-spinners";

export default function PrixCentre({ prix }) {
  const [prixCentre, setPrixCentre] = useState(prix);
  const [pageError, setPageError] = useState(false);
  const queryClient = useQueryClient();

  async function ModifPrix(data) { // data = ["2024-02-07", {"16":[250, 300, 350],"32":[300, 350, 400]}]
    const link = `${process.env.REACT_APP_API_URL}/admin/prixUGs/centre`;
    // const link = "http://localhost:8080/admin/prixUGs/centre"; 
    const response = await fetch(link, {
      method: "POST",
      body: JSON.stringify(data), 
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const dataReceived = await response.json();
    if (!response.ok) {
      throw dataReceived;
    }
    return dataReceived;
  }

  const mutationPrixPost = useMutation({
    mutationFn: (data) => ModifPrix(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["HistoriquePrix", "Admin"]);
    },
  });

  const change = (e, idx, surface) => {
    if (e.target.name == "prixDdeb") {
      setPrixCentre((prev) => {
        const newState = JSON.parse(JSON.stringify(prev))
        newState[0] = e.target.value;
        return [...newState];
      });
    } else {
        setPrixCentre((prev) => {
          const newState = JSON.parse(JSON.stringify(prev))
          if (e.target.value === "") {// check if empty, input type number is weird and take into account strings
            newState[1][surface][idx] = "0"
          } else {
            const strValue = e.target.value.replace(/^0+/, '') || "0"
            newState[1][surface][idx] = strValue
          }
          return [...newState];
        }); 
    }
  };

    const validPrix = () => {
        if (prixCentre[0] == undefined || prixCentre[0] == "") {
            setPageError(true);
        } else {
            setPageError(false)
            mutationPrixPost.mutate(prixCentre)
        }
  };


  return (
    <div className={styles.table}>
      <table className={styles.tableSearch}>
        <thead>
          <tr>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          <th style={{ width: "25%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Date de début</b>
            </td>
            <td>
              <input
                type="date"
                id="prixDdeb"
                name="prixDdeb"
                data-testid="prixDdeb"
                value={prixCentre[0]}
                onChange={(e) => change(e)}
                className={styles.input}
              />
              {(prixCentre[0] == undefined || prixCentre[0] == "") &&
                  pageError == true && <small style={{marginLeft: "1rem"}}>Ce champs est requis</small>}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>Superficie</b>
            </td>
            <td>
              <b>Montant Centre d'affaires</b>
            </td>
            <td>
              <b></b>
            </td>
            <td>
              <b></b>
            </td>
          </tr>
          {prixCentre.length != 0 &&
            Object.entries(prixCentre[1]).map(([key, prices]) => {
              return (
                <tr key={key}>
                  <td>
                    <b>{key}m²</b>
                  </td>
                  <td>
                    <input
                      type="number"
                      id={key}
                      data-testid={key}
                      name={key}
                      min="0"
                      value={prices[0]}
                      onChange={(e) => change(e, 0, key)}
                      className={styles.input}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <button className={styles.buttonValid} onClick={() => validPrix()} disabled={mutationPrixPost.isPending}>Enregistrer</button>
      <div className={styles.bounce}>
      {(mutationPrixPost.isPending == true) && 
        <BounceLoader color="black" />
        }
      </div>

    </div>
  );
}

